@tailwind base;@tailwind components;@tailwind utilities;*{scroll-behavior:smooth;}body{font-display: swap; font-family:"Merriweather",serif;margin:0;padding:0;overflow-x:hidden;width:100vw;}h1{font-size:24px;}h2,h3{font-size:18px;}p{font-size:13px;color:#4b5563;}span{font-size:12px;}.bg-custom-blue{background-color:#1E3A8A;}.h-800{max-height:800px;}.h-500{height:500px;}.news-item{margin-bottom:20px;}.description-container{position:relative;max-height:4.5em;overflow:hidden;}.description-text{position:relative;margin:0;padding-right:1em;background:linear-gradient(to bottom,transparent 50%,white 100%);-webkit-mask-image:linear-gradient(to bottom,black 50%,transparent 100%);mask-image:linear-gradient(to bottom,black 50%,transparent 100%);}.banner{width:100%;overflow:hidden;height:400px;}.carousel{display:flex;transition:transform 1s ease-in-out;}.slide{width:100%;height:100%;object-fit:cover;position:absolute;top:0;left:0;opacity:0;transition:opacity 1s ease-in-out;}.slide.active{opacity:1;}.container::-webkit-scrollbar{width:12px;}.container::-webkit-scrollbar-track{background:#f0f0f0;}.container::-webkit-scrollbar-thumb{background-color:#f57f17;border-radius:10px;border:3px solid #f0f0f0;}.container::-webkit-scrollbar-thumb:hover{background-color:#e64a19;}.container{scrollbar-color:#f57f17 #f0f0f0;scrollbar-width:thin;}@media only screen and (max-width:768px){h1{font-size:22px;}h2,h3{font-size:16px;}p{font-size:12px;}span{font-size:12px;}}@keyframes spin{0%{transform:rotate(0deg);}100%{transform:rotate(360deg);}}.spinner{border:8px solid #f3f3f3;border-top:8px solid #3498db;border-radius:50%;width:50px;height:50px;animation:spin 2s linear infinite;margin:auto;}.break-words {
    word-wrap: break-word;
    overflow-wrap: break-word;
} 
.whitespace-normal {
  white-space: normal; 
}

.overflow-hidden {
  overflow: hidden; 
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
